$breakpoint-tablet: 870px;
$breakpoint-mobile: 600px;
$breakpoint-specific: 1218px;
$breakpoint-custom: 700px;

.header-container {
  width: 100vw;
  height: 64px;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-left: 10px;
  padding-right: 40px;
  @media (max-width: 480px) {
    padding-left: 0px;
    padding-right: 10px;
    justify-content: space-between;
  }
  .logo-col {
    width: 220px;
    height: 100%;

    padding: 10px;
    display: flex;
    align-items: center;

    img {
      width: 100%;
      height: auto;
    }
  }
  .menu-col {
    @media (max-width: 480px) {
      display: none;
    }
    ul {
      display: flex;
      gap: 30px;
      align-items: center;
      Button {
        background-color: #dbe9fb;
        color: #2d94f3;
        border: none;
        transition: 0.2s ease-in-out;
        font-weight: 700;
        &:hover {
          background-color: #b3c3d9;
        }
      }
      li {
        list-style: none;
        font-size: 18px;
        color: white;
        transition: 0.5s ease-in-out;
        cursor: pointer;
        position: relative;
        a {
          color: white;
        }
        &::after {
          content: "";
          width: 0px;
          height: 5px;
          bottom: 0px;
          left: 0px;
          background-color: aqua;
          position: absolute;
          transition: 0.8s ease-in-out;
        }

        &:hover {
          color: white;
          &::after {
            content: "";
            width: 100%;
            height: 5px;
            bottom: 0px;
            left: 0px;
            background-color: rgb(5, 64, 112);
            position: absolute;
          }
        }
      }
    }
  }
  .hamburger {
    display: none;
    @media (max-width: 480px) {
      display: block;
    }

    svg {
      font-size: 22px;
      color: white;
    }
  }
}
