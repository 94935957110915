.detail-section-container {
  padding: 100px;
  h1 {
    font-size: 25px;
  }
  p {
    font-size: 18px;
    font-weight: 300;
    letter-spacing: normal;
    line-height: 30px;
  }
}
