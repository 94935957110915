@keyframes up {
  0% {
    transform: translateY(-100px);
    opacity: 0;
  }
  20% {
    transform: translateY(0px);
    opacity: 0.7;
  }
  100% {
    opacity: 0.8;
    transform: translateY(0px);
  }
}
.mySwiper {
  height: 100vh;
  position: relative;
  @media (max-width: 480px) {
    height: 100%;
  }
  .overlay {
    position: absolute;
    width: 100vw;
    height: 100%;
    z-index: 2;
    top: 0;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);

    .overlay-col {
      position: absolute;
      bottom: 200px;
      width: 40%;
      height: 250px;
      left: 100px;
      animation: up 5s infinite;
      padding-left: 50px;
      @media (max-width: 480px) {
        bottom: 10px;
        left: 10px;
        width: 80%;
        height: auto;
        padding-left: 10px;
      }
      h1 {
        font-size: 42px;
        margin-bottom: 3px;
        @media (max-width: 480px) {
          font-size: 18px;
        }
      }
      p {
        margin-top: 0px;
        font-size: 18px;
        @media (max-width: 480px) {
          font-size: 13px;
        }
      }
    }
  }
  .img {
    height: 100%;
    width: 100%;
  }
}
.testimonial-container {
  width: 100%;
  height: auto;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 0, 0, 0),
    rgba(182, 193, 253, 0.5)
  );
  .section-3 {
    margin-top: 5%;
    .heading-col {
      width: 50%;
      height: 100%;
      text-align: center;
      @media (max-width: 480px) {
        width: calc(100% - 10px);
      }
      h1 {
        font-size: 34px;
        color: #2d94f3;
        margin-bottom: 5px;
        @media (max-width: 480px) {
          font-size: 24px;
        }
      }
      p {
        font-size: 1.04rem;
        line-height: 1.8;
        font-weight: 400;
        @media (max-width: 480px) {
          font-size: 13px;
        }
      }
    }
  }
  .slider-container {
    height: auto;
    .slide {
      margin-bottom: 10px;

      .hui {
        margin-inline: 30px;
        background-color: white;
        position: relative;
        box-shadow: 0 9px 30px rgba(7, 65, 210, 0.1);
        border-radius: 10px;

        .client-profile {
          position: absolute;
          top: 10px;
          left: -30px;
          width: 60px;
          height: 60px;
          border-radius: 100px;
          background-size: cover;
        }
        .info {
          padding: 10px 40px;
          h5 {
            margin-bottom: 0px;
            margin-top: 0px;
          }
          h6 {
            margin-bottom: 0px;
            margin-top: 5px;
            color: #2d94f3;
          }
          p {
            font-size: 0.85rem;
            line-height: 1.7;
            margin-bottom: 0;
            text-align: left;

            @media (max-width: 480px) {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
