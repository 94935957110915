.form-container {
  width: 100vw;
  height: auto;
  display: flex;
  justify-content: center;
  margin-top: 70px;

  .form-col {
    width: 60%;
    height: auto;
    box-shadow: 0 9px 30px rgba(7, 65, 210, 0.1);
    border-radius: 10px;
    margin-bottom: 10px;
    padding-top: 30px;
    padding-bottom: 30px;
    position: relative;
    @media (max-width: 480px) {
      width: calc(100% - 10px);
    }
    .mail {
      position: absolute;
      top: -90px;
      left: 220px;
      transform: rotate(-20deg);
    }
    h1 {
      padding-left: 28px;
      font-size: 34px;
      margin-bottom: 0px;
      margin-top: 0px;
    }
    .top {
      display: flex;
      justify-content: space-around;
      padding: 20px;
      gap: 10px;
      .left-col {
        width: 49%;
        display: flex;
        flex-direction: column;
        label {
          // padding-left: 5px;
          // margin-top: 10px;
          padding-bottom: 10px;
        }
        input {
          width: 100%;
          margin-top: 10px;
          height: 35px;
          border-radius: 5px;
          border: none;
          outline: none;
          margin-bottom: 10px;
          &::placeholder {
            padding-left: 5px;
          }
        }
        .select {
          width: 100%;
          width: 350;
          border: none;
          height: 35;
        }
      }
      .right-col {
        width: 49%;
        display: flex;
        flex-direction: column;
        label {
          // padding-left: 5px;
          // margin-top: 10px;
          padding-bottom: 10px;
        }
        input {
          width: 100%;
          margin-top: 10px;
          height: 35px;
          margin-bottom: 10px;
          border-radius: 5px;
          border: none;
          outline: none;
          &::placeholder {
            padding-left: 5px;
          }
        }
        .select {
          width: 100%;
          width: 350;
          border: none;
          height: 35;
        }
      }
    }
    .bottom {
      display: flex;
      // padding-left: 20px;
      width: 100%;
      justify-content: flex-start;
      flex-direction: column;
      padding: 20px;
      .text-area {
        border: none;
        margin-top: 10px;
        outline: none;
        width: 100%;
        height: 150px;
        padding: 10px;
      }
    }
  }
}
