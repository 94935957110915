.blog-card-container {
  width: 100vw;
  height: auto;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 100px;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  @media (max-width: 480px) {
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    gap: 5px;
  }
}
