// stack-wrapper

.stack-wrapper {
  margin-top: 20px;
  gap: 70px;
  .holder {
    .title-holder {
      display: flex;
      gap: 20px;
      align-items: center;

      h4 {
        font-size: 1.3rem;
        font-weight: 600;
        margin: 0;
      }
    }
  }

  .tech-lists {
    display: flex;
    align-items: center;
    gap: 50px;
    margin-top: 30px;
    justify-content: flex-start;

    .list-item {
      display: flex;
      gap: 5px;
      flex-direction: column;
      align-items: center;

      p {
        margin: 0;
        color: #0d264c;
        opacity: 0.7;
        font-weight: 500;
      }
    }
  }
}

// we-development

.web-development {
  padding: 50px 0;
  position: relative;
}
// app-development

.app-development {
  padding: 50px 0;
  background-color: white;
}

// ui-ux-design

.ui-ux-design {
  padding: 50px 0;
}

// .devops-services
.devops-services {
  padding: 50px 0;
  background-color: white;
}

// qa-testing

.qa-testing {
  padding: 50px 0;
}

// data-engineering

.data-engineering {
  padding: 50px 0;
  background-color: white;
}

// portfolio-&-contact
.portfolio__contact {
  padding: 50px 0;
  .holder-item {
    .title {
      line-height: 1.6;
      font-weight: 600;
      margin-bottom: 0;
      letter-spacing: 0.05rem;
      font-size: 1.65rem;
      color: #2d94f3;
    }
    .desc {
      max-width: 60%;
      opacity: 0.71;
      color: #0d264c;
      font-size: 1.2rem;
    }
  }
}
