.blog-card {
  width: 440px;
  height: 512px;
  border-radius: 5px;
  overflow: hidden;
  @media (max-width: 480px) {
    width: 100%;
    height: 400px;
  }
  .img-col-top {
    width: 100%;
    height: 50%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .card-detail-bottom {
    h1 {
      margin-top: 5px;
    }
    // padding: 20px;
  }
  p {
    @media (max-width: 480px) {
      font-size: 12px;
    }
  }

  Button {
    border: none;
    background-color: #2d94f3;
    color: white;
  }
}
