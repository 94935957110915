.blog-banner-container {
  width: 100vw;
  height: 400px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  // background-position-y: -130px;
  //   object-fit: cover;
  @media (max-width: 480px) {
    height: 200px;
    width: 100%;
    // background-position-y: -100px;
  }
}
