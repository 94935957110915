.section-2 {
  margin-top: 5%;
  background-color: #ffe8e8;
  opacity: 0.8;
  clip-path: polygon(100% 0, 100% 100%, 52% 100%, 0 100%, 0 52%, 44% 31%);
  @media (max-width: 480px) {
    clip-path: none;
  }
  .heading-col {
    h1 {
      @media (max-width: 480px) {
        font-size: 22px;
      }
    }
    p {
      @media (max-width: 480px) {
        font-size: 14px;
      }
    }
  }
  .col-2 {
    margin-top: 6%;
  }
}

.services-card-container {
  width: 100vw;
  height: auto;
  display: flex;
  justify-content: center;

  .services-col {
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
    @media (max-width: 480px) {
      width: 100%;
    }
  }
  .card {
    width: 350px;
    height: auto;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    transition: 0.5s ease-in-out;
    &:hover {
      box-shadow: 0 9px 30px rgba(7, 65, 210, 0.1);
      translate: 0px -10px;
    }
    border-radius: 10px;
    h4 {
      font-size: 1.3rem;
      font-weight: 600;
      margin: 0;
      color: #0d264c;
      transition: 0.5s ease-in-out;
      cursor: pointer;
      &:hover {
        color: #2d94f3;
      }
    }
    p {
      font-size: 0.95rem;
      font-weight: 400;
      margin: 1rem 0 0;
      opacity: 0.75;
      color: #0d264c;
    }
  }
}
.view-more-btn {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}
