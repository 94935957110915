.container {
  max-width: 1240px;
  margin: 0 auto;
}

// Global-btn

.btn {
  padding: 0.5rem 1.1rem 0.4rem;
  line-height: 1.6;
  font-size: 0.95rem;
  font-weight: 500;
  border-radius: 5px;
  border-width: 2px;
}
