.page-intro-header {
  text-align: center;
  padding: 140px 0;

  .header-title {
    font-size: 2.6rem;
    line-height: 1.35;
    font-weight: 600;
    margin: 0;
  }
  .header-sub-title {
    margin: 1rem 0;
    line-height: 1.5;
    color: #2d94f3;
    text-transform: uppercase;
    font-weight: 500;
  }
  .header-desc {
    font-size: 1.1rem;
    line-height: 1.9;
    color: #0d264c;
  }
}
