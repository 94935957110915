.about-section-container {
  width: calc(100vw - 10px);
  height: auto;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 480px) {
    flex-direction: column;
  }
  .left-col-about-section {
    width: 50%;
    height: 500px;
    @media (max-width: 480px) {
      width: 100%;
      height: auto;
    }
  }
  .right-col-about-section {
    width: 50%;
    height: 500px;
    padding: 40px 30px 0px 30px;
    @media (max-width: 480px) {
      width: 100%;
      padding: 0px;
      height: auto;
    }
    h1 {
      font-size: 25px;
      line-height: 1.3;
      font-size: 34px;
      font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
    }
    p {
      color: #4d4d4d;
      font-size: 16px;
      line-height: 30px;
    }
  }
  .ul-list {
    display: flex;
    align-items: flex-start;
    gap: 0px;
    @media (max-width: 480px) {
      flex-direction: column;
      margin: 0px;
    }
    ul {
      padding-left: 5px;
      @media (max-width: 480px) {
        margin: 0px;
      }
      li {
        list-style: none;
        display: flex;
        margin-top: 20px;
        align-items: center;
        gap: 10px;
        @media (max-width: 480px) {
          padding-top: 0px;
        }
        svg {
          color: green;
        }
      }
    }
  }
}
