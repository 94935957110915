.btn-ele {
  background-color: #2d94f3;
  border-color: #2d94f3;
  color: white;
  transition: 0.5s ease-in-out;
  font-size: 14px;
  display: flex;
  padding: 20px;
  align-items: center;
  svg {
    font-size: 24px;
  }
  &:hover {
    background-color: white;
    color: white;
  }
}
