.about-section-container-2 {
  width: calc(100vw - 10px);
  height: auto;
  padding: 0px 10px 0px 10px;
  display: flex;
  margin-top: 5%;
  justify-content: space-between;
  @media (max-width: 480px) {
    flex-direction: column;
  }
  .about-left-col-2 {
    width: 50%;
    height: 500px;
    padding: 20px 40px 0px 40px;

    @media (max-width: 480px) {
      padding: 0px;
      width: 100%;
      height: auto;
    }
    h1 {
      font-size: 25px;
      line-height: 1.3;
      font-size: 34px;
      font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
    }
  }
  .about-right-col-2 {
    width: 50%;
    height: 500px;
    padding: 40px 30px 0px 30px;
    display: flex;
    align-items: center;
    @media (max-width: 480px) {
      flex-direction: column;
      width: 100%;
      height: auto;
    }
    .card-left-col {
      width: auto;
      height: 100%;
      display: flex;
      gap: 10px;
      padding: 10px;
      flex-direction: column;
      @media (max-width: 480px) {
        padding: 0px;
        width: 100%;
      }
    }
    .card-right-col {
      width: auto;
      height: 100%;
      padding: 10px;
      display: flex;
      gap: 10px;
      margin-top: 50px;
      flex-direction: column;
      @media (max-width: 480px) {
        padding: 0px;
        width: 100%;
        margin-top: 10px;
      }
    }
  }
}
