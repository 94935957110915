.drawer {
  background-color: black !important;
  .ant-drawer-body {
    padding: 0px;
  }
  .sider-menu-col {
    display: flex;
    flex-direction: column;
    // align-items: center;
    ul {
      padding: 0px;
      margin-top: 15%;
      a {
        color: white;
        li {
          padding: 10px 1px;
          list-style: none;
          font-size: 16px;
          padding-left: 15px;
        }
      }
    }
    Button {
      margin-top: 10px;
      margin-bottom: 10px;
      margin: 10px;
    }
  }
}
.services {
  padding: 0px 15px !important;
  //   background-color: rgb(159, 158, 158);
  a {
    font-size: 24px;
  }
}
