.stack-intro {
  .header-title {
    line-height: 1.6;
    font-weight: 600;
    margin-bottom: 0;
    letter-spacing: 0.05rem;
    font-size: 1.65rem;
    color: #2d94f3;
  }

  p {
    max-width: 60%;
    font-weight: 500;
    opacity: 0.71;
    color: #0d264c;
    font-size: 1.2rem;
  }
}
